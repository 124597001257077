<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <g-card page-title="Site Ownership" page-icon="mdi-lock-reset">
    <g-list-page
        :columns="columns"
        :headers="headers"
        :actions="actions"
        :show-default-action-buttons="false"
        :action-buttons="actionButtons"
        sort="site_url"
        stateendpoint="siteOwnership.siteOwnerships"
        @loadData="loadData"
        @editItem="editItem"
        @switchHandler="switchHandler"
    >
      <template v-slot:overlay>
        <page-overlay :overlay="isOverlay" />
      </template>
      <template v-slot:top>
        <v-row>
          <v-col cols="12" md="6" sm="8">
            <g-p-search
                hint="Minimum two characters needed to search. Columns(site_url)"
                @applySearch="handleSearch"
            />
          </v-col>
        </v-row>
      </template>
    </g-list-page>
    <component
        is="SiteOwnershipPopup"
        ref="SiteOwnershipPopup"
        :item="editedItem"
        :show="showPopup"
        @closePopupHandler="closePopupHandler"
        @onPopupShow="openPopUp"
        @saveItemHandler="saveItemHandler"
    />
  </g-card>
</template>
<script>
import ListMixins from "../../components/mixins/list/List";
import SiteOwnershipPopup from "../../components/popups/SiteOwnershipPopup";
import GCard from "../../components/GCard";
import PageOverlay from "../../components/PageOverlay";
import GPSearch from "../../components/GPSearch";
import GListPage from "../../components/list/GListPage";

export default {
  name: 'SiteOwnership',
  metaInfo: {
    title: 'rabbiitfirm.com',
    titleTemplate: 'Admin Dashboard - Site Ownership | %s',
    meta: [
      { name: 'robots', content: 'noindex' },
      { name: 'googlebot', content: 'noindex' }
    ]
  },
  components: {
    GListPage,
    GPSearch,
    PageOverlay,
    GCard,
    SiteOwnershipPopup
  },
  mixins: [ListMixins],
  data() {
    return {
      headers: [
        {
          text: 'Site URL',
          value: 'site_url'
        },
        {
          text: 'Site Owner',
          value: 'site_owner.name'
        },
        {
          text: 'Status',
          value: 'is_active',
          cellClass: "center-switch",
          align: "center",
        },
        {
          text: '', value: 'actions', sortable: false
        }
      ],
      actions: {
        load: 'siteOwnership/load',
        update: 'siteOwnership/update'
      },
      actionButtons: [
        {
          category: 'edit',
          icon: 'mdi-pencil',
          color: '',
          text: 'Set Site Ownership',
          clickHandler: 'editItem'
        }
      ],
      columns: [
        {
          name: "is_active",
          callback: ""
        },
      ],
    }
  },
}
</script>
